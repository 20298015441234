@import url('https://fonts.googleapis.com/css2?family=Island+Moments&display=swap');

@media (min-width: 1025px) {
  .landing-page {
    background-color: #baf0fc;
    display: flex;
    flex-direction: column; /* Changed to column for better layout */
    align-items: center; /* Center align items */
    width: 100vw;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
}
@media only screen and (max-width: 1024px) {
  .landing-page {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    transform: scale(1);
  }
}

.landing-page {
  background-color: #baf0fc;
  display: flex;
  flex-direction: column; /* Changed to column for better layout */
  align-items: center; /* Center align items */
  width: 100%;
}

.landing-page .div {
  background-color: #baf0fc;
  height: 4686px;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 1440px;
}

.landing-page .overlap-group {
  height: 3607px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -20px;
  width: 100%;
  max-width: 1440px;
}

/* Global link styles */
a {
  text-decoration: none;
  color: #000; /* Change this to your desired color */
}

a:visited {
  color: #000; /* Change this to your desired color */
}

/* Example of targeting specific classes */
.landing-page .website-under a,
.landing-page .buy-button a,
.landing-page .text-wrapper-7 a,
.landing-page .text-wrapper-8 a {
  color: #000; /* Change this to your desired color */
}

.landing-page .website-under a:visited,
.landing-page .buy-button a:visited,
.landing-page .text-wrapper-7 a:visited,
.landing-page .text-wrapper-8 a:visited {
  color: #000; /* Change this to your desired color */
}

.landing-page .rectangle {
  background-color: #67e3ff;
  height: 2669px;
  left: 0;
  position: absolute;
  top: 938px;
  width: 1370px;
}

.landing-page .designer {
  opacity: 0.5;
  height: 1263px;
  left: 52px;
  object-fit: cover;
  position: absolute;
  top: 991px;
  width: 1263px;
}

.landing-page .rectangle-2 {
  background-color: #67e3ff;
  height: 208px;
  left: 1058px;
  position: absolute;
  top: 674px;
  width: 310px;
}

.landing-page .rectangle-xz {
  background-color: #baf0fc;
  height: 78px;
  left: 1073px;
  position: absolute;
  top: 794px;
  width: 280px;
  z-index: 100;
}

.landing-page .rectangle-xa {
  background-color: #000000;
  height: 225px;
  left: 033px;
  position: absolute;
  top: 2290px;
  width: 1298px;
  z-index: 00;
 
}

.landing-page .rectangle-buy {
  background-color: #e4f755;
  height: 75px;
  left: 933px;
  position: absolute;
  top: 3360px;
  width: 298px;
  z-index: 01;
  opacity: 0.6;
}
.landing-page .rectangle-button {
  background-color: #000000;
  height: 225px;
  left: 033px;
  position: absolute;
  top: 3290px;
  width: 1298px;
  z-index: 00;
  
}

.landing-page .rectangle-3 {
  background-color: #67e3ff;
  height: 170px;
  left: 4px;
  position: absolute;
  top: 31px;
  width: 1366px;
}

.landing-page .website-under {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 1074px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 743px;
  width: 280px;
  opacity: 0.7;
  font-weight: bold;
  z-index: 101;
}

.landing-page .buy-button {
  color: #dbdcbd;
  font-family: "Irish Grover", Helvetica;
  font-size: 46px;
  font-weight: 400;
  left: 947px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 3384px;
  width: 280px;
  font-weight: bold;
  z-index: 1010;
}

.landing-page .medium-link {
  position: absolute;
  top: 890px;
  left: 1225px;
  width: 143px;
  height: 36px;
}

.landing-page .medium-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.landing-page .img {
  height: 347px;
  left: 706px;
  object-fit: cover;
  position: absolute;
  top: 500px;
  width: 664px;
}

.landing-page .text-wrapper {
  -webkit-text-stroke: 1.1px #000000;
  color: #ffffff;
  font-family: "Irish Grover", Helvetica;
  font-size: 145px;
  font-weight: 400;
  height: 218px;
  left: 281px;
  letter-spacing: 0;
  line-height: 217.5px;
  opacity: 0.85;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.landing-page .designer-2 {
  height: 1163px;
  left: 53px;
  object-fit: cover;
  position: absolute;
  top: 2281px;
  width: 1262px;
}

.landing-page .arrow-image {
  position: absolute;
  top: 895px;
  left: 1116px;
  width: 75px;
  height: 35px;
  opacity: 0.87; /* 87% opacity */
  object-fit: cover;
  z-index: 10; /* Ensure it is on top */
  transform: rotate(-14deg);
}

.landing-page .medium-text {
  position: absolute;
  top: 934px;
  left: 1000px;
  font-family: 'Island Moments', cursive;
  font-size: 49px;
  font-weight: 400;
  width: 320px;
  height: 53px;
  line-height: 53px;
  text-align: center;
  color: #000000;
}

.landing-page .pie-chart-no-bg {
  height: 240px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2296px;
  width: 1368px;
  z-index: 100;
  
}
.landing-page .fish {
  height: 1340px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  top: 2266px;
  width: 1368px;
  z-index: 100;
  opacity: 0.3;
  z-index: 0;
}

.landing-page .designer-logo {
  height: 619px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 228px;
  width: 1023px;
  }

.landing-page .sardana {
  height: 150px;
  left: 1192px;
  object-fit: cover;
  position: absolute;
  top: 51px;
  width: 148px;
}

.landing-page .no-tax-mint-revoked {
  -webkit-text-stroke: 1px #000000;
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 45px;
  font-weight: 400;
  height: 340px;
  left: 565px;
  letter-spacing: 0;
  line-height: 67.5px;
  position: absolute;
  text-align: center;
  top: 2564px;
  z-index: 10;
}

.landing-page .no-tax-mint-revoked-bg {
  -webkit-text-stroke: 70px rgba(146, 248, 255, 0.87); /* Apply the stroke with the specified color and opacity */
  text-shadow: 
    25px 0 0 rgba(146, 248, 255, 0.87),
    -25px 0 0 rgba(146, 248, 255, 0.87),
    0 25px 0 rgba(146, 248, 255, 0.87),
    0 -39px 0 rgba(146, 248, 255, 0.87),
    16px 16px 0 rgba(146, 248, 255, 0.87),
    -16px 16px 0 rgba(146, 248, 255, 0.87),
    16px -16px 0 rgba(146, 248, 255, 0.87),
    -16px -16px 0 rgba(146, 248, 255, 0.87);
  color: #ffffff;
  font-family: "Irish Grover", Helvetica;
  font-size: 45px;
  font-weight: 400;
  height: 340px;
  left: 565px;
  letter-spacing: 0;
  line-height: 67.5px;
  position: absolute;
  text-align: center;
  top: 2564px;
}

.landing-page .text-wrapper-3 {
  -webkit-text-stroke: 1.1px #000000;
  color: #ffffff;
  font-family: "Irish Grover", Helvetica;
  font-size: 60px;
  font-weight: 400;
  height: 90px;
  left: 604px;
  letter-spacing: 0;
  line-height: 90px;
  opacity: 0.85;
  position: absolute;
  top: 152px;
  white-space: nowrap;
}

.landing-page .text-wrapper-4 {
  -webkit-text-stroke: 1.1px #000000;
  color: #ffffff;
  font-family: "Irish Grover", Helvetica;
  font-size: 100px;
  font-weight: 400;
  height: 150px;
  left: 188px;
  letter-spacing: 0;
  line-height: 150px;
  opacity: 0.85;
  position: absolute;
  top: 819px;
  white-space: nowrap;
}

.landing-page .in-the-vast-digital {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  text-align: center;
  top: 1132px;
  width: 1301px;
}

.landing-page .p {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 60px;
  font-weight: 400;
  left: 117px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  text-align: center;
  top: 1019px;
  white-space: nowrap;
}

.landing-page .allocation-the-bg-2 {
  -webkit-text-stroke: 70px rgba(146, 248, 255, 0.87); /* Apply the stroke with the specified color and opacity */
  text-shadow: 
    25px 0 0 rgba(146, 248, 255, 0.87),
    -25px 0 0 rgba(146, 248, 255, 0.87),
    0 25px 0 rgba(146, 248, 255, 0.87),
    0 -39px 0 rgba(146, 248, 255, 0.87),
    16px 16px 0 rgba(146, 248, 255, 0.87),
    -16px 16px 0 rgba(146, 248, 255, 0.87),
    16px -16px 0 rgba(146, 248, 255, 0.87),
    -16px -16px 0 rgba(146, 248, 255, 0.87);
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 1px;
  line-height: 60px;
  position: absolute;
  top: 2831px;
  width: 600px;
  text-align: left;
}

.landing-page .allocation-the-2 {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 40px;
  font-weight: 400;
  left: 140px;
  letter-spacing: 1px;
  line-height: 60px;
  position: absolute;
  top: 2831px;
  width: 600px;
  text-align: left;
  z-index: 10;
}

.landing-page .allocation-the-bg {
  -webkit-text-stroke: 70px rgba(146, 248, 255, 0.87); /* Apply the stroke with the specified color and opacity */
  text-shadow: 
    25px 0 0 rgba(146, 248, 255, 0.87),
    -25px 0 0 rgba(146, 248, 255, 0.87),
    0 25px 0 rgba(146, 248, 255, 0.87),
    0 -39px 0 rgba(146, 248, 255, 0.87),
    16px 16px 0 rgba(146, 248, 255, 0.87),
    -16px 16px 0 rgba(146, 248, 255, 0.87),
    16px -16px 0 rgba(146, 248, 255, 0.87),
    -16px -16px 0 rgba(146, 248, 255, 0.87);
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 150px;
  letter-spacing: 1px;
  line-height: 60px;
  position: absolute;
  top: 2961px;
  width: 600px;
  text-align: center;
  z-index: 5;
}

.landing-page .allocation-the {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 48px;
  font-weight: 400;
  left: 150px;
  letter-spacing: 1px;
  line-height: 60px;
  position: absolute;
  top: 2961px;
  width: 600px;
  text-align: center;
  z-index: 10;
}

.landing-page .text-wrapper-5 {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 60px;
  font-weight: 400;
  left: 270px;
  letter-spacing: 0;
  line-height: 105px;
  position: absolute;
  text-align: center;
  top: 3471px;
  white-space: nowrap;
}

.landing-page .overlap {
  height: 853px;
  left: 0px;
  position: absolute;
  top: 3635px;
  width: 1370px;
}

.landing-page .DALLE {
  height: 783px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1370px;
}

.landing-page .rectangle-4 {
  background-color: #67e3ff;
  height: 160px;
  left: 0;
  position: absolute;
  top: 686px;
  width: 1370px;
}




.landing-page .twitter-container {
  width: 100%;
  max-width: 310px; /* Adjust as needed */
  margin: 20px auto; /* Center horizontally and add vertical margin */
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e1e8ed;
  position: absolute;
  top: 210px;
  left: 1058px; /* Allows for positioning adjustments */
  z-index: 102;
}

/* Customize the tweet content */
.landing-page .twitter-tweet p {
  font-size: 16px;
  color: #333;
}

/* Customize the link colors */
.landing-page .twitter-tweet a {
  color: #1da1f2;
}

/* Ensure all images and embeds are responsive */
img, .twitter-container {
  max-width: 100%;
  height: auto;
}



.landing-page .text-wrapper-6 {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 60px;
  font-weight: 400;
  left: 734px;
  letter-spacing: 0;
  line-height: 90px;
  position: absolute;
  top: 782px;
  white-space: nowrap;
  width: 636px;
}

.landing-page .text-wrapper-7 {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 48px;
  left: 1099px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 701px;
  width: 271px;
}

.landing-page .text-wrapper-8 {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 48px;
  left: 1080px;
  letter-spacing: 0;
  line-height: 48px;
  position: absolute;
  top: 742px;
  width: 290px;
}

.landing-page .text-wrapper-9 {
  -webkit-text-stroke: 1px #000000;
  color: #ffffff;
  font-family: "Irish Grover", Helvetica;
  font-size: 42px;
  font-weight: 400;
  height: 63px;
  left: 835px;
  letter-spacing: 0;
  line-height: 63px;
  position: absolute;
  top: 713px;
  width: 216px;
}

.landing-page .sardana-2 {
  height: 124px;
  left: 20px;
  object-fit: cover;
  position: absolute;
  top: 702px;
  width: 126px;
}

.landing-page .RISK-WARNING-trading {
  color: #000000;
  font-family: "Irish Grover", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 180px;
  left: -10px;
  letter-spacing: 0;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 4494px;
  width: 1387px;
}
