@import "https://fonts.googleapis.com/css2?family=Island+Moments&display=swap";
@media (width >= 1025px) {
  .landing-page {
    background-color: #baf0fc;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    display: flex;
    overflow-x: hidden;
  }
}

@media only screen and (width <= 1024px) {
  .landing-page {
    overflow-x: hidden;
    transform: scale(1);
  }
}

.landing-page {
  background-color: #baf0fc;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.landing-page .div {
  background-color: #baf0fc;
  width: 100%;
  max-width: 1440px;
  height: 4686px;
  position: relative;
  overflow: hidden;
}

.landing-page .overlap-group {
  width: 100%;
  max-width: 1440px;
  height: 3607px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

a {
  color: #000;
  text-decoration: none;
}

a:visited, .landing-page .website-under a, .landing-page .buy-button a, .landing-page .text-wrapper-7 a, .landing-page .text-wrapper-8 a, .landing-page .website-under a:visited, .landing-page .buy-button a:visited, .landing-page .text-wrapper-7 a:visited, .landing-page .text-wrapper-8 a:visited {
  color: #000;
}

.landing-page .rectangle {
  background-color: #67e3ff;
  width: 1370px;
  height: 2669px;
  position: absolute;
  top: 938px;
  left: 0;
}

.landing-page .designer {
  opacity: .5;
  object-fit: cover;
  width: 1263px;
  height: 1263px;
  position: absolute;
  top: 991px;
  left: 52px;
}

.landing-page .rectangle-2 {
  background-color: #67e3ff;
  width: 310px;
  height: 208px;
  position: absolute;
  top: 674px;
  left: 1058px;
}

.landing-page .rectangle-xz {
  z-index: 100;
  background-color: #baf0fc;
  width: 280px;
  height: 78px;
  position: absolute;
  top: 794px;
  left: 1073px;
}

.landing-page .rectangle-xa {
  z-index: 0;
  background-color: #000;
  width: 1298px;
  height: 225px;
  position: absolute;
  top: 2290px;
  left: 33px;
}

.landing-page .rectangle-buy {
  z-index: 1;
  opacity: .6;
  background-color: #e4f755;
  width: 298px;
  height: 75px;
  position: absolute;
  top: 3360px;
  left: 933px;
}

.landing-page .rectangle-button {
  z-index: 0;
  background-color: #000;
  width: 1298px;
  height: 225px;
  position: absolute;
  top: 3290px;
  left: 33px;
}

.landing-page .rectangle-3 {
  background-color: #67e3ff;
  width: 1366px;
  height: 170px;
  position: absolute;
  top: 31px;
  left: 4px;
}

.landing-page .website-under {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  opacity: .7;
  z-index: 101;
  width: 280px;
  font-family: Irish Grover, Helvetica;
  font-size: 24px;
  font-weight: bold;
  line-height: 25px;
  position: absolute;
  top: 743px;
  left: 1074px;
}

.landing-page .buy-button {
  color: #dbdcbd;
  letter-spacing: 0;
  text-align: center;
  z-index: 1010;
  width: 280px;
  font-family: Irish Grover, Helvetica;
  font-size: 46px;
  font-weight: bold;
  line-height: 25px;
  position: absolute;
  top: 3384px;
  left: 947px;
}

.landing-page .medium-link {
  width: 143px;
  height: 36px;
  position: absolute;
  top: 890px;
  left: 1225px;
}

.landing-page .medium-img {
  object-fit: cover;
  opacity: .8;
  width: 100%;
  height: 100%;
}

.landing-page .img {
  object-fit: cover;
  width: 664px;
  height: 347px;
  position: absolute;
  top: 500px;
  left: 706px;
}

.landing-page .text-wrapper {
  -webkit-text-stroke: 1.1px #000;
  color: #fff;
  letter-spacing: 0;
  opacity: .85;
  white-space: nowrap;
  height: 218px;
  font-family: Irish Grover, Helvetica;
  font-size: 145px;
  font-weight: 400;
  line-height: 217.5px;
  position: absolute;
  top: 0;
  left: 281px;
}

.landing-page .designer-2 {
  object-fit: cover;
  width: 1262px;
  height: 1163px;
  position: absolute;
  top: 2281px;
  left: 53px;
}

.landing-page .arrow-image {
  opacity: .87;
  object-fit: cover;
  z-index: 10;
  width: 75px;
  height: 35px;
  position: absolute;
  top: 895px;
  left: 1116px;
  transform: rotate(-14deg);
}

.landing-page .medium-text {
  text-align: center;
  color: #000;
  width: 320px;
  height: 53px;
  font-family: Island Moments, cursive;
  font-size: 49px;
  font-weight: 400;
  line-height: 53px;
  position: absolute;
  top: 934px;
  left: 1000px;
}

.landing-page .pie-chart-no-bg {
  object-fit: cover;
  z-index: 100;
  width: 1368px;
  height: 240px;
  position: absolute;
  top: 2296px;
  left: 0;
}

.landing-page .fish {
  object-fit: cover;
  z-index: 100;
  opacity: .3;
  z-index: 0;
  width: 1368px;
  height: 1340px;
  position: absolute;
  top: 2266px;
  left: 0;
}

.landing-page .designer-logo {
  object-fit: cover;
  width: 1023px;
  height: 619px;
  position: absolute;
  top: 228px;
  left: 4px;
}

.landing-page .sardana {
  object-fit: cover;
  width: 148px;
  height: 150px;
  position: absolute;
  top: 51px;
  left: 1192px;
}

.landing-page .no-tax-mint-revoked {
  -webkit-text-stroke: 1px #000;
  color: #000;
  letter-spacing: 0;
  text-align: center;
  z-index: 10;
  height: 340px;
  font-family: Irish Grover, Helvetica;
  font-size: 45px;
  font-weight: 400;
  line-height: 67.5px;
  position: absolute;
  top: 2564px;
  left: 565px;
}

.landing-page .no-tax-mint-revoked-bg {
  -webkit-text-stroke: 70px #92f8ffde;
  text-shadow: 25px 0 #92f8ffde, -25px 0 #92f8ffde, 0 25px #92f8ffde, 0 -39px #92f8ffde, 16px 16px #92f8ffde, -16px 16px #92f8ffde, 16px -16px #92f8ffde, -16px -16px #92f8ffde;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  height: 340px;
  font-family: Irish Grover, Helvetica;
  font-size: 45px;
  font-weight: 400;
  line-height: 67.5px;
  position: absolute;
  top: 2564px;
  left: 565px;
}

.landing-page .text-wrapper-3 {
  -webkit-text-stroke: 1.1px #000;
  color: #fff;
  letter-spacing: 0;
  opacity: .85;
  white-space: nowrap;
  height: 90px;
  font-family: Irish Grover, Helvetica;
  font-size: 60px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 152px;
  left: 604px;
}

.landing-page .text-wrapper-4 {
  -webkit-text-stroke: 1.1px #000;
  color: #fff;
  letter-spacing: 0;
  opacity: .85;
  white-space: nowrap;
  height: 150px;
  font-family: Irish Grover, Helvetica;
  font-size: 100px;
  font-weight: 400;
  line-height: 150px;
  position: absolute;
  top: 819px;
  left: 188px;
}

.landing-page .in-the-vast-digital {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1301px;
  font-family: Irish Grover, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 1132px;
  left: 31px;
}

.landing-page .p {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Irish Grover, Helvetica;
  font-size: 60px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 1019px;
  left: 117px;
}

.landing-page .allocation-the-bg-2 {
  -webkit-text-stroke: 70px #92f8ffde;
  text-shadow: 25px 0 #92f8ffde, -25px 0 #92f8ffde, 0 25px #92f8ffde, 0 -39px #92f8ffde, 16px 16px #92f8ffde, -16px 16px #92f8ffde, 16px -16px #92f8ffde, -16px -16px #92f8ffde;
  color: #000;
  letter-spacing: 1px;
  text-align: left;
  width: 600px;
  font-family: Irish Grover, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 2831px;
  left: 140px;
}

.landing-page .allocation-the-2 {
  color: #000;
  letter-spacing: 1px;
  text-align: left;
  z-index: 10;
  width: 600px;
  font-family: Irish Grover, Helvetica;
  font-size: 40px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 2831px;
  left: 140px;
}

.landing-page .allocation-the-bg {
  -webkit-text-stroke: 70px #92f8ffde;
  text-shadow: 25px 0 #92f8ffde, -25px 0 #92f8ffde, 0 25px #92f8ffde, 0 -39px #92f8ffde, 16px 16px #92f8ffde, -16px 16px #92f8ffde, 16px -16px #92f8ffde, -16px -16px #92f8ffde;
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  z-index: 5;
  width: 600px;
  font-family: Irish Grover, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 2961px;
  left: 150px;
}

.landing-page .allocation-the {
  color: #000;
  letter-spacing: 1px;
  text-align: center;
  z-index: 10;
  width: 600px;
  font-family: Irish Grover, Helvetica;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  position: absolute;
  top: 2961px;
  left: 150px;
}

.landing-page .text-wrapper-5 {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Irish Grover, Helvetica;
  font-size: 60px;
  font-weight: 400;
  line-height: 105px;
  position: absolute;
  top: 3471px;
  left: 270px;
}

.landing-page .overlap {
  width: 1370px;
  height: 853px;
  position: absolute;
  top: 3635px;
  left: 0;
}

.landing-page .DALLE {
  object-fit: cover;
  width: 1370px;
  height: 783px;
  position: absolute;
  top: 0;
  left: 0;
}

.landing-page .rectangle-4 {
  background-color: #67e3ff;
  width: 1370px;
  height: 160px;
  position: absolute;
  top: 686px;
  left: 0;
}

.landing-page .twitter-container {
  z-index: 102;
  background-color: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 10px;
  width: 100%;
  max-width: 310px;
  margin: 20px auto;
  padding: 10px;
  position: absolute;
  top: 210px;
  left: 1058px;
}

.landing-page .twitter-tweet p {
  color: #333;
  font-size: 16px;
}

.landing-page .twitter-tweet a {
  color: #1da1f2;
}

img, .twitter-container {
  max-width: 100%;
  height: auto;
}

.landing-page .text-wrapper-6 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  width: 636px;
  font-family: Irish Grover, Helvetica;
  font-size: 60px;
  font-weight: 400;
  line-height: 90px;
  position: absolute;
  top: 782px;
  left: 734px;
}

.landing-page .text-wrapper-7 {
  color: #000;
  letter-spacing: 0;
  width: 271px;
  height: 48px;
  font-family: Irish Grover, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: absolute;
  top: 701px;
  left: 1099px;
}

.landing-page .text-wrapper-8 {
  color: #000;
  letter-spacing: 0;
  width: 290px;
  height: 48px;
  font-family: Irish Grover, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  position: absolute;
  top: 742px;
  left: 1080px;
}

.landing-page .text-wrapper-9 {
  -webkit-text-stroke: 1px #000;
  color: #fff;
  letter-spacing: 0;
  width: 216px;
  height: 63px;
  font-family: Irish Grover, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 63px;
  position: absolute;
  top: 713px;
  left: 835px;
}

.landing-page .sardana-2 {
  object-fit: cover;
  width: 126px;
  height: 124px;
  position: absolute;
  top: 702px;
  left: 20px;
}

.landing-page .RISK-WARNING-trading {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 1387px;
  height: 180px;
  font-family: Irish Grover, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: absolute;
  top: 4494px;
  left: -10px;
}
/*# sourceMappingURL=index.1a0b098f.css.map */
